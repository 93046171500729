import type { OttoNexus } from "../lib/nexus/index.js";

export * from "../lib/nexus/index.js";

export type * from "../lib/nexus/namespace/namespace.types.js";
export type * from "../lib/nexus/event/event.types.js";
export type * from "../lib/nexus/function/function.types.js";
export type * from "../lib/nexus/proxy/proxy.types.js";

export * from "../lib/nexus/function/public.js";
export * from "../lib/nexus/event/public.js";
export * from "../lib/nexus/namespace/public.js";

/**
 *
 */
/*                                            */
export const otto: OttoNexus = window.otto ?? {};
