/*                                                   */
import { o_global } from "@gr-common/head/namespaces";

import * as browser from "../../lib/browser/index.js";
import * as animation from "../../lib/animation/index.js";
import * as cookie from "../../lib/cookie/index.js";
import * as core from "../../lib/core/index.js";
import * as dom from "../../lib/dom/head.js";
import * as event from "../../lib/event/index.js";
import * as history from "../../lib/history/index.js";
import * as misc from "../../lib/misc/index.js";
import * as hardcore from "../../lib/hardcore/index.js";
import * as legacy from "../../lib/deprecated/index.js";

o_global.helper ||= {};
const { helper } = o_global;

/*                        */

helper.bind = core.bind;
helper.clone = core.clone;
helper.convertStringToFunction = core.convertStringToFunction;
helper.extend = core.extend;
helper.removeFromArray = core.removeFromArray;
helper.isPlainObject = core.isPlainObject;
helper.isEmptyObject = core.isEmptyObject;
helper.cloneFunction = (core as any).cloneFunction;

/*                          */
helper.cookieExist = cookie.exists;
helper.getCookie = cookie.get;
helper.getCookieValue = cookie.get;
helper.removeCookie = cookie.remove;
helper.setCookie = cookie.set;

/*                         */
helper.delegate = event.delegate;
helper.stopEvent = event.stop;
helper.whichTransitionEvent = event.whichTransitionEndEvent;
helper.addEvent = (event as any).add;
helper.getEventTarget = (event as any).getTarget;
helper.preventDefault = (event as any).preventDefault;
helper.removeEvent = (event as any).remove;
helper.stopPropagation = (event as any).stopPropagation;

/*                       */
helper.hasClassInParents = dom.hasClassInParents;
helper.getParentByClassName = dom.getParentByClassName;
helper.stringToDocumentFragment = dom.stringToDocumentFragment;
helper.hasClass = (dom as any).hasClass;
helper.removeClass = (dom as any).removeClass;
helper.toggleClass = (dom as any).toggleClass;
helper.getElementsByClassname = (dom as any).getElementsByClassname;
helper.addClass = (dom as any).addClass;

/*                             */
helper.Easings = animation.easings;
helper.requestAnimFrame = animation.requestAnimFrame;
helper._getNewScrollPosition = legacy.getNewScrollPosition;
helper.scrollTo = animation.scrollTo;

/*                        */
helper.ajax = legacy.ajaxLegacy;

/*                           */
helper.getScrollbarWidth = browser.getScrollbarWidth;
helper.getStyle = browser.getStyle;
helper.isIE8 = browser.isIE8;

/*                           */
helper.setScrollRestoration = history.setScrollRestoration;
helper.resetScrollRestoration = history.resetScrollRestoration;

/*                        */
helper.isResponsive = misc.isResponsive;
helper.isValidMouseButton = misc.isValidMouseButton;
helper.setVendorStyle = misc.setVendorStyle;

/*                            */
helper.evalScript = hardcore.evalScript;
helper.executeInlineScripts = hardcore.executeInlineScripts;

/*                     */
/*                                                   */
o_global.referrer = document.referrer;

/*                                                   */
o_global.getCookieValue = helper.getCookieValue;
o_global.convertStringToFunction = helper.convertStringToFunction;
o_global.isResponsive = helper.isResponsive;
