import { legacyCookie, removeCookie, writeCookie } from "./cookie.js";
import { logLevels } from "./level.js";
import { DebugConfig, LogLevelInput, StatusText, updateStatus } from "./status.js";
import { noop, writeLog, Writer } from "./writer.js";
import { loggerDataKey, logExtFunctions } from "./extensions.js";
import type { Debug, Logger, Scope, ScopeName } from "./index.js";

/**
 *
 *
 *
 *
 *
 */
export function scope<NS extends string = ScopeName>(scopeName: NS): Scope<NS> {
  return {
    scopeName,
    namespace: scopeName,
    /*                                                               */
    scope: (segment) => logger(`${scopeName}.${segment}`),
  };
}

/*                                   */
let write: Writer = noop;

/**
 *
 *
 *
 */
export const logExtensions = logExtFunctions.map((k) => {
  return [
    k,
    function (this: Logger, ...data: Parameters<Console[typeof k]>): void {
      write(...this[loggerDataKey], data, k);
    },
  ] as const;
}) as { [P in (typeof logExtFunctions)[number] as number]: [P, Console[P]] }[number][];

/**
 *
 *
 *
 *
 *
 */
export function logger<NS extends string = ScopeName>(scopeName: NS): Debug<NS> {
  const res = scope(scopeName) as Debug<NS>;
  res.log = (level, ...data) => write(level, scopeName, data);
  res.table = (level, ...data) => write(level, scopeName, data, "table");
  res.scope = (segment) => logger(`${scopeName}.${segment}`);

  logLevels.forEach((l) => {
    const logFunc = ((...data) => write(l, scopeName, data)) as Logger;
    logFunc[loggerDataKey] = [l, scopeName];
    logExtensions.forEach((wrapper) => {
      /*                                            */
      logFunc[wrapper[0]] = wrapper[1];
    });
    res[l] = logFunc;
  });

  return res;
}

/**
 *
 *
 *
 *
 */
export function updateWriter(state: DebugConfig): DebugConfig {
  write = state.enabled ? writeLog : noop;
  return state;
}

/**
 *
 *
 *
 *
 */
export function activate(
  namespace?: string | RegExp,
  level?: LogLevelInput,
  optins: { colors?: boolean } = {},
): DebugConfig {
  /*                                 */
  const config = updateStatus({ pattern: new RegExp(namespace || ".*"), level, ...optins });

  /*                                  */
  writeCookie(config);

  /*                                     */
  return updateWriter(config);
}

/**
 *
 *
 */
export function setColors(enabled: boolean): DebugConfig {
  /*                                 */
  const config = updateStatus({ colors: enabled });

  /*                                  */
  return writeCookie(config);
}

/**
 *
 *
 */
export function setLevel(level: LogLevelInput): DebugConfig {
  /*                                 */
  const config = updateStatus({ level });

  /*                                  */
  return writeCookie(config);
}

/**
 *
 */
export function activateLegacy(): StatusText {
  /*                  */
  legacyCookie();

  /*                       */
  return updateStatus({ activated: true }).text;
}

/**
 *
 *
 *
 */
export function deactivate(): DebugConfig {
  /*            */
  removeCookie();

  /*                       */
  return updateWriter(updateStatus({ pattern: null, activated: false }));
}

/**
 *
 *
 *
 */
/*                                            */
export const warn = logger("legacy.warn").warn;
