import type { EasingFunc } from "./easings";
import { getTime } from "../utils/date.js";

/**
 *
 *
 *
 *
 *
 */
/*                                                          */
export function requestAnimFrame(callback: FrameRequestCallback, ...args: unknown[]): number {
  /*                                                          */
  const w: any = window;
  return (
    (w.requestAnimationFrame ||
      w.webkitRequestAnimationFrame ||
      w.mozRequestAnimationFrame ||
      ((cb: FrameRequestCallback) => window.setTimeout(cb, 1000 / 60))) as typeof requestAnimFrame
  ).apply(null, [callback, ...args]);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function getNewScrollPosition(
  from: number,
  to: number,
  currentState: number,
  easingFnc: EasingFunc,
): number {
  return from - (from - to) * easingFnc(currentState);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function scrollToInternal(
  scrollBase: HTMLElement,
  scrollFrom: number,
  scrollTo: number,
  startTime: number,
  duration: number,
  easingFnc: EasingFunc,
): void {
  const base = scrollBase;
  const current = getTime();
  /*                                                                           */
  const start = startTime === 0 ? current - 1 : startTime;
  const currentState = (current - start) / duration;

  /*                              */
  if (currentState > 1) {
    base.scrollTop = scrollTo;
    return;
  }

  /*                                                                 */
  base.scrollTop = getNewScrollPosition(scrollFrom, scrollTo, currentState, easingFnc);

  /*                                        */
  requestAnimFrame(() => {
    scrollToInternal(scrollBase, scrollFrom, scrollTo, start, duration, easingFnc);
  });
}
