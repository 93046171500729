/*                                  */
import type { QBus } from "@otto-ec/event-q-bus";
import type { EventLoaderEvents } from "./index.js";
import { store, type ListenerContainer } from "./store.js";

/**
 *
 *
 *
 *
 *
 *
 */
export function sortByPriority<T extends { priority: number }>(queue: T[]): T[] {
  return queue.sort((a, b) => {
    return a.priority - b.priority;
  });
}

/*                                                          */
export function invokeListener<A>(ev: A): (listener: ListenerContainer) => void {
  return (handler) => {
    try {
      handler.fn(ev as never);
    } /*                  */ catch (e) {
      Promise.resolve().then(() => {
        throw e;
      });
    }
  };
}

/**
 *
 */
export function handleOnReady(ev: Event): void {
  store.onReadyEvent = ev || {};
  store.onReadyQueue = sortByPriority(store.onReadyQueue);
  store.onReadyQueue.forEach(invokeListener(store.onReadyEvent));

  /*                                      */
  (window.o_global.eventQBus as QBus<EventLoaderEvents> | undefined)?.emitRetain(
    "assets.events.documentReady",
  );
  otto.dom.onReady.dispatch(ev, { retain: true });
}

/**
 *
 */
export function initOnReady(): void {
  document.addEventListener("DOMContentLoaded", handleOnReady, false);
}

/**
 *
 *
 */
export function handleAllJavascriptLoaded(): void {
  store.allJavascriptLoadedComplete = store.onLoadFired && store.allPreloadScriptsLoadedFired;

  if (store.allJavascriptLoadedComplete) {
    store.allJavascriptLoadedQueue = sortByPriority(store.allJavascriptLoadedQueue);
    store.allJavascriptLoadedQueue.forEach((c) => c.fn({} as Event));
  }

  if (store.allJavascriptLoadedComplete && !store.allJavascriptLoadedFired) {
    /*                                      */
    /*                   */
    (window.o_global.eventQBus as QBus<EventLoaderEvents> | undefined)?.emitRetain(
      "assets.events.allJavascriptLoaded",
    );
    store.allJavascriptLoadedEvent = true;
  }
}

/**
 *
 *
 */
let oldOnLoadFunction:
  | (((this: GlobalEventHandlers, ev: Event) => void) & ((this: Window, ev: Event) => void))
  | null;

/**
 *
 */
/*                                                                                                             */
export function handleOnLoad(this: any, ev: Event): any {
  if (oldOnLoadFunction) {
    oldOnLoadFunction.call(this, ev);
  }

  store.onLoadEvent = ev || {};
  store.onLoadQueue = sortByPriority(store.onLoadQueue);
  store.onLoadQueue.forEach(invokeListener(store.onLoadEvent));

  handleAllJavascriptLoaded();

  /*                                      */
  (window.o_global.eventQBus as QBus<EventLoaderEvents> | undefined)?.emitRetain(
    "assets.events.windowLoad",
    ev,
  );
  otto.dom.onLoad.dispatch(ev, { retain: true });
}

/**
 *
 *
 */
export function initOnLoad(): void {
  oldOnLoadFunction = window.onload;
  window.onload = handleOnLoad;
}

export function handleAllPreloadScriptsLoaded(ev: Event): void {
  store.allPreloadScriptsLoadedEvent = ev || {};
  store.allPreloadScriptsLoadedQueue = sortByPriority(store.allPreloadScriptsLoadedQueue);
  store.allPreloadScriptsLoadedQueue.forEach(invokeListener(store.allPreloadScriptsLoadedEvent));

  handleAllJavascriptLoaded();

  /*                                                                                  */
  /*                                                                        */
}

/**
 *
 *
 */
export function initAllPreloadScriptsLoaded(): void {
  /*                                               */
  document.addEventListener("AllScriptsExecuted", handleAllPreloadScriptsLoaded, false);
}
