import { transition, transitions } from "./transition";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function fadeOut<T extends HTMLElement, F extends (...args: never[]) => void>(
  target: T,
  callback?: F,
): void {
  if (window.getComputedStyle(target).opacity !== "0") {
    transition(target, transitions.fadeOut, callback);
  } else if (typeof callback === "function") {
    callback();
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function fadeIn<T extends HTMLElement, F extends (...args: never[]) => void>(
  target: T,
  callback?: F,
): void {
  if (window.getComputedStyle(target).opacity !== "1") {
    transition(target, transitions.fadeIn, callback);
  } else if (typeof callback === "function") {
    callback();
  }
}
