/**
 *
 *
 *
 *
 */

import { otto } from "./nexus.js";

export type * from "../lib/debug/index.js";
export const { logger, scope, Level, status } = window.o_global?.debug ?? {};
export const { sendLog } = otto.debug ?? {};
