/*                                             */
/**
 *
 *
 */
function checkStorage(storage?: Storage): Storage | undefined {
  if (storage) {
    try {
      storage.setItem("storageTest", "test");
      storage.getItem("storageTest");
      storage.removeItem("storageTest");
      return storage;
    } catch {
      /*            */
    }
  }
  return undefined;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export type StorageWrapperFunc = () => Storage;

/*                                           */
type StorageEx = Omit<Storage, "length"> & Pick<Partial<Storage>, "length">;

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export class GlobalStorage implements StorageEx {
  /**
 *
 *
 */
  private storage?: Storage;

  /**
 *
 */
  public get isAvailable(): boolean {
    return !!this.storage;
  }

  /**
 *
 *
 */
  public get length(): number | undefined {
    /*                                          */
    return this.storage ? Object.keys(this.storage).length : undefined;
  }

  /**
 *
 *
 *
 *
 *
 */
  public constructor(storageObjectOrFunction: Storage | StorageWrapperFunc) {
    /*                                       */
    if (
      window.o_util?.cookie?.exists("app") &&
      navigator.userAgent.indexOf("OS 11_3 like Mac OS X") !== -1
    ) {
      return;
    }

    /*                                                   */
    /*                                                  */
    /*                           */
    if (typeof storageObjectOrFunction === "function") {
      try {
        this.storage = storageObjectOrFunction();
      } catch {
        /*            */
      }
    } else {
      this.storage = storageObjectOrFunction;
    }

    this.storage = checkStorage(this.storage);
  }

  /**
 *
 *
 *
 *
 */
  isStorageAvailable(): boolean {
    return !!checkStorage(this.storage);
  }

  /**
 *
 *
 *
 */
  key(index: number): string | null {
    return this.storage ? this.storage.key(index) : null;
  }

  /**
 *
 *
 *
 *
 *
 *
 */
  setItem(key: string, value: string): boolean {
    if (this.storage && arguments.length === 2) {
      try {
        this.storage.setItem(key, value);
        return !!this.storage.getItem(key);
      } catch (err) {
        const e = err as Error;
        /*                                            */
        if (typeof window.AS?.RUM?.sendCustomRequest === "function") {
          window.AS.RUM.sendCustomRequest("localStorageError", {
            message: `${e.toString()} | ${window.location.href} | ${navigator.userAgent}`,
          });
        }
      }
    }

    return false;
  }

  /**
 *
 *
 *
 *
 */
  getItem(key: string): string | null {
    if (this.storage) {
      return this.storage.getItem(key);
    }
    return null;
  }

  /**
 *
 *
 *
 *
 */
  removeItem(key: string): boolean {
    if (this.storage && !!key) {
      this.storage.removeItem(key);
      return !this.storage.getItem(key);
    }

    return false;
  }

  /**
 *
 *
 *
 */
  clear(): boolean {
    if (this.storage && this.length !== undefined) {
      /*                                             */
      Object.keys(this.storage).forEach((k) => this.removeItem(k));
      /*                                            */
      return this.length < 1;
    }

    return false;
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  setJson<T extends object>(key: string, data: T): boolean {
    try {
      return this.setItem(key, JSON.stringify(data));
    } catch {
      return false;
    }
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  getJson<T extends object>(key: string): T | null {
    try {
      const item = this.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch {
      return null;
    }
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function globalStorage(
  storageObjectOrFunction: Storage | StorageWrapperFunc,
): GlobalStorage {
  return new GlobalStorage(storageObjectOrFunction);
}
