import type { UserToggle } from "./index.js";

/**
 *
 *
 */
export type NormalizedToggle = [value: boolean, allowOverride: boolean];

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export type TogglesData = { [toggleName: string]: UserToggle };

export const COOKIE_PREFIX = "toggle_";

/**
 *
 *
 *
 *
 *
 */
export function normalize(toggle: UserToggle, defaultValue: unknown): NormalizedToggle {
  /*                                                   */
  if (toggle === undefined) {
    return [!!defaultValue, false];
  }

  /*                                  */
  if (Array.isArray(toggle)) {
    return [!!toggle[0], !!toggle[1]];
  }

  /*                                            */
  return [!!toggle, false];
}

/**
 *
 *
 *
 *
 */
export function normalizeLegacy(toggle: UserToggle, defaultValue: unknown): NormalizedToggle {
  if (toggle === undefined) {
    return [!!defaultValue, false];
  }

  /*                                                                              */
  if (!!toggle === false) {
    return [false, true];
  }

  /*                                                        */
  return [true, false];
}
